<template>
  <ig-page-base
    :loading="loading"
    :is-boxed="true"
    :title="$t('pageRegister.title')"
    class="page-register">

    <ig-form-schema
      :schema="customSchema"
      :model="form"
      @submit="onRegister">
      <template slot="actions">
        <div class="field">
          <b-button
            class="is-uppercase page-register__buttons"
            type="is-primary"
            native-type="submit"
            expanded>
            {{ $t('pageRegister.form.firstStep.submit') }}
          </b-button>
        </div>

        <div class="field">
          <p class="is-size-7 has-text-centered">
            {{ $t('pageRegister.login') }}
            <router-link
              :to="{ name: 'page-login' }"
              class="button is-text is-link is-primary has-text-weight-bold">
              {{ $t('pageRegister.loginLink') }}
            </router-link>
          </p>
        </div>
      </template>
    </ig-form-schema>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { IgPageBase, IgFormSchema } from '@integrai/ui';

const { mapState, mapMutations, mapActions } = createNamespacedHelpers('register');

export default Vue.extend({
  name: 'page-register',
  components: {
    IgPageBase,
    IgFormSchema,
  },
  computed: {
    ...mapState([
      'loading',
      'partners',
      'form',
    ]),
    customSchema() {
      return {
        fields: [
          {
            name: 'name',
            component: 'input',
            fieldAttrs: {
              label: this.$t('pageRegister.form.firstStep.name.label'),
            },
            attrs: {
              placeholder: this.$t('pageRegister.form.firstStep.name.placeholder'),
            },
            validation: {
              name: this.$t('pageRegister.form.firstStep.name.label'),
              rules: 'required|min:5',
            },
          },
          {
            name: 'storeUrl',
            component: 'input',
            fieldAttrs: {
              label: this.$t('pageRegister.form.firstStep.storeUrl.label'),
            },
            attrs: {
              placeholder: this.$t('pageRegister.form.firstStep.storeUrl.placeholder'),
            },
            validation: {
              name: this.$t('pageRegister.form.firstStep.storeUrl.label'),
              rules: 'required|checkUrl',
            },
          },
          {
            name: 'email',
            component: 'input',
            fieldAttrs: {
              label: this.$t('pageRegister.form.firstStep.email.label'),
            },
            attrs: {
              placeholder: this.$t('pageRegister.form.firstStep.email.placeholder'),
              'icon-pack': 'fas',
              icon: 'envelope',
            },
            validation: {
              name: this.$t('pageRegister.form.firstStep.email.label'),
              rules: 'required|email|uniqueField:email',
            },
          },
          {
            name: 'password',
            componentPrefix: 'ig',
            component: 'password',
            fieldAttrs: {
              label: this.$t('pageRegister.form.firstStep.password.label'),
            },
            attrs: {
              placeholder: this.$t('pageRegister.form.firstStep.password.placeholder'),
              'icon-pack': 'fas',
              icon: 'key',
              type: 'password',
              'password-reveal': true,
            },
            validation: {
              name: this.$t('pageRegister.form.firstStep.password.label'),
              rules: 'required|min:8|numbers|letters',
              vid: 'password',
              'data-vv-as': this.$t('pageRegister.form.firstStep.password.label'),
            },
          },
          {
            name: 'confirmPassword',
            component: 'input',
            fieldAttrs: {
              label: this.$t('pageRegister.form.firstStep.confirmPassword.label'),
            },
            attrs: {
              placeholder: this.$t('pageRegister.form.firstStep.confirmPassword.placeholder'),
              'icon-pack': 'fas',
              icon: 'key',
              type: 'password',
              'password-reveal': true,
            },
            validation: {
              name: this.$t('pageRegister.form.firstStep.confirmPassword.label'),
              customMessages: {
                confirmed: this.$t('pageRegister.form.firstStep.confirmPassword.confirmed'),
              },
              rules: 'required|confirmed:password',
              slim: true,
              'data-vv-as': this.$t('pageRegister.form.firstStep.password.label'),
            },
          },
          {
            name: 'phone',
            component: 'input',
            fieldAttrs: {
              label: this.$t('pageRegister.form.firstStep.phone.label'),
            },
            attrs: {
              placeholder: this.$t('pageRegister.form.firstStep.phone.placeholder'),
              'v-mask': "'cellphone'",
            },
            validation: {
              name: this.$t('pageRegister.form.firstStep.phone.label'),
              rules: 'required|min:14',
            },
          },
          {
            name: 'partner',
            component: 'select',
            fieldAttrs: {
              label: this.$t('pageRegister.form.firstStep.partner.label'),
            },
            attrs: {
              placeholder: this.$t('pageRegister.form.firstStep.partner.placeholder'),
              expanded: true,
            },
            options: [
              {
                value: null,
                label: this.$t('pageRegister.form.firstStep.partner.options.none'),
              },
              ...this.partners.map((partner) => ({
                value: partner.slug,
                label: partner.name,
              })),
            ],
          },
          {
            name: 'termsAndConditionsAgree',
            component: 'checkbox',
            attrs: {
              size: 'is-small',
            },
            content: `${this.$t('pageRegister.form.firstStep.termsAndConditionsAgree.label')}
              <a
                href="/auth/terms-and-conditions"
                target="_blank"
                class="button is-text is-link is-primary has-text-weight-bold"
                title="${this.$t('pageRegister.form.firstStep.termsAndConditionsAgree.link')}">
                ${this.$t('pageRegister.form.firstStep.termsAndConditionsAgree.link')}
              </a>
            `,
            validation: {
              name: this.$t('pageRegister.form.firstStep.storePlatform.label'),
              rules: 'required',
            },
          },
        ],
      };
    },
  },
  methods: {
    ...mapMutations([
      'setForm',
    ]),
    ...mapActions([
      'getPartners',
      'register',
      'oauth',
    ]),
    onRegister(data) {
      this.setForm(data);
      this.register(this.form)
        .then(() => {
          const { return: returnUrl } = this.$route.query;

          if (returnUrl) {
            // TODO: Gambiarra Nuvemshop
            window.location.href = returnUrl.indexOf('62a7c36abeb5cbce2173e4a0') ? decodeURIComponent(returnUrl).split('?')[0] : returnUrl;
          } else {
            this.$router.push({
              name: 'page-register-success',
            });
          }
        });
    },
  },
  async created() {
    this.getPartners();
    this.setForm(this.$route.query);

    const { return: returnUrl } = this.$route.query;

    // TODO: Gambiarra Nuvemshop
    /* eslint-disable */
    if (returnUrl.indexOf('62a7c36abeb5cbce2173e4a0')) {
      const code = decodeURIComponent(returnUrl).split('?')[1].replace('code=', '');

      const {
        access_token,
        user_id,
      } = await this.oauth(code);

      this.setForm({
        access_token,
        user_id,
      });
    }
    /* eslint-enable */
  },
});
</script>
<style lang="scss">
.page-register {
  width: 100%;

  .box.ig-box {
    max-width: 500px;
  }

  &__buttons {
    margin-top: 1.5rem;
    font-weight: bold;
  }

  &__tutorial {
    margin: 2rem 0;
  }
}
</style>
